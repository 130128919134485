<script lang="ts">
  let stats: any

  const today = formatDateOffset()

  let fromdate: string
  let todate: string
  let isloading: boolean = false
  let maxValue: number

  $: fromdate = formatDateOffset(-3)
  $: todate = formatDateOffset(0)
  $: maxValue = Array.isArray(stats) ? Math.max(...stats) : null

  function handleFromDateChange(e) {
    fromdate = e.target.value
  }

  function handleToDateChange(e) {
    todate = e.target.value
  }

  async function loadData() {
    isloading = true

    let formData = new FormData()

    formData.append('sesskey', window.M.cfg.sesskey)
    formData.append('fromdate', fromdate)
    formData.append('todate', todate)

    const response = await fetch('/report/userstats/ajax.php', {
      method: 'POST', mode: 'cors', credentials: 'same-origin',
      body: formData
    })

    if (response.ok) {
      stats = await response.json()
      isloading = false
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    loadData()
  }

  function formatDateOffset(offset = 0) {
    const aDay = 24*60*60*1000
    const offsetTime = Date.now() + offset * aDay;
    const offsetDate = new Date(offsetTime)

    let year:any = offsetDate.getFullYear()
    let month:any = offsetDate.getMonth() + 1
    month = month < 10 ? `0${month}` : month
    let day:any = offsetDate.getDate()
    day = day < 10 ? `0${day}` : day

    return `${year}-${month}-${day}`
  }
</script>

<div class="userstats-table">

  <form action="/report/userstats/ajax.php" method="post" on:submit={handleSubmit}>
    <label for="fromdate">Von</label>
    <input id="fromdate" name="fromdate" type="date" value={fromdate} max={today} on:change={handleFromDateChange} />
    <label for="todate">Bis</label>
    <input id="todate" name="todate" type="date" value={todate} max={today} on:change={handleToDateChange}/>
    {#if isloading}
      <input disabled value="Lade..." type="submit" /> :
    {:else}
      <input value="Anfordern" type="submit" />
    {/if}
  </form>

  {#if stats?.length}
    <div class="userstats-table__bars">
      {#each stats as count, i}
        <div class="userstats-table__bar-frame" title={`${count} Aufrufe`}>
          <div class="userstats-table__bar" style={`height: ${Math.round(count / maxValue * 100)}%`} title={`${count} Aufrufe`}></div>
          <div class="userstats-table__label">{i}</div>
        </div>
      {/each}
    </div>
  {/if}
</div>
